import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <div className="page-not-found section section--centered">
            <div className="overlay overlay--image"></div>
            <div className="overlay overlay--ink"></div>
            <div className="overlay overlay--color"></div>
            <div className="site-width">
                <h1>404</h1>
                <h2>
                    It seems you've gone astray, but it's not too late to order.
                </h2>
                <button type="button" className="cta-btn">
                    Order Now
                </button>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
